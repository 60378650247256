<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-col class="d-flex" cols="12" sm="1">
          <v-tooltip bottom color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="componentDialog = !componentDialog"
                v-bind="attrs"
                v-on="on"
                right
                fab
                small
                color="success white--text"
                style="margin: 5px"
              >
                <v-icon>mdi-printer-outline</v-icon>
              </v-btn>
            </template>
            <span>Print Report</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" sm="12" >
          <v-card
              class="mx-auto my-12"
              max-width="100%"
            >
                <v-card-text>
                <h3>
                  Project Development Objectives(s)
                </h3>
                </v-card-text>
              <v-divider class="mx-4"></v-divider>
                <v-card-text>
              <p>

                  To increase access to high quality broadband internet services for government, businesses and citizens, and to improve the government's capacity to
                  deliver digital public services.
              </p>
                </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" sm="12" 
            v-for="component in component_list"
            :key="component">
          <div
            style="padding: 10px"
          >


          <v-card
              class="mx-auto my-1"
              max-width="100%"
            >
                <v-card-text>
                  <h3> {{component.name}}</h3>
                </v-card-text>
              <v-divider class="mx-4"></v-divider>

              <v-card-text>
              <table>
                <thead>
                  <tr>
                    <th rowspan="2"><strong>S/No.</strong></th>
                    <th rowspan="2"><strong>Indicator Name</strong></th>
                    <th colspan="2" rowspan="2"><strong>Baseline</strong></th>
                    <th colspan="2"><strong>Intermediate Targets</strong></th>
                    <th colspan="2" rowspan="2"><strong>End Targets</strong></th>
                    <th colspan="2" rowspan="2"><strong>Progress</strong></th>
                  </tr>
                  <tr>
                    <th><strong>1</strong></th>
                    <th><strong>2</strong></th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="item in reportData" :key="item.name">
                  <td>1.0</td>
                  <td>Uncertainty of sustainable funding from central Government and difficulties in collecting debts from customers who are Public Institutions depending on Government subvention.</td>
                  <td colspan="2">{{ item.duration }}</td>
                  <td>{{ item.duration }}</td>
                  <td>{{ item.duration }}</td>
                  <td colspan="2">{{ item.duration }}</td>
                  <td colspan="2">
                            <span>
                            <v-progress-linear
                            
                              :color="
                                item.progress > 60
                                  ? 'green'
                                  : item.progress < 45
                                  ? 'orange'
                                  : 'blue'
                              "
                              v-model="item.progress"
                              :buffer-value="100"
                              height="20"
                            >
                              <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                              </template>
                            </v-progress-linear>
                          </span>
                  </td>
                </tr>
                </tbody>
                </table>
              </v-card-text>
          </v-card>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="componentDialog" max-width="60%">
        <v-card>
          <v-card-title><v-icon>mdi-plus</v-icon> New Target</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
          <v-textarea
            outlined
            name="input-5-3"
            label="Describe Briefly About the Target"
          ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="pink darken-1"
              class="white--text"
              small
              @click="componentDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success darken-1"
              small
              class="white--text"
              @click="componentDialog = false"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </sequential-entrance>
</template>

<script>
export default {
  name: "DashBoard",
  data: () => ({
    hovered: true,
    overlay: false,
    componentDialog: false,
    detail: "/components/details/",
    sample: 1,
    component_list: [
      { name: "Project Development Objective Indicators", id: 1 },
      { name: "Intermediate Results Indicators by Components", id: 2 },
    ],
      reportData: [
        {
          name: `Percentage of the population covered by
                a mobile broadband network signal (3G,
                4G or higher) (Percentage)`,
          duration: 52.00,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 34,
        },
        {
          name: `Number of Government Ministries,
                  Departments and Agencies benefitting
                  from broadband internet service
                  (Number)`,
          duration: 240.00 ,
          startDate: "January 4, 2022",
          endDate: "December 4, 2025",
          progress: 69,
        },
        {
          name: `Individuals (aged 15 and above) using the
                internet, per 100 inhabitants, of which
                percentage female (Percentage)`,
          duration: 25.00 ,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 49,
        },
        {
          name: `Percentage of internet users who are
                female (Percentage) `,
          duration: 46.70 ,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 10,
        },
        {
          name: `Number of monthly transactions
                accessing a public service via internet or a mobile phone (Number)`,
          duration: 0.00,
          startDate: "January 4, 2022",
          endDate: "December 4, 2025",
          progress: 69,
        },
      ],
  }),
};
</script>


<style scoped>
.chart {
  height: 400px;
}

table {
  border-collapse: collapse;
  width: 100%;
  padding: 10px;
}

/* thead { */
  /* background-color: var(--dtp-primary-color); */
  /* border: 1px solid $wrrb-primary-color; */
  /* color: var(--dtp-font-white); */
/* } */

th {
  text-align: center;
  padding: 5px;
  border-top: none;
  border: 0.2px solid  var(--dtp-font-dark);
}
td {
  text-align: left;
  padding: 5px;
  border-top: none;
  border: 1px solid var(--dtp-font-dark);
}
</style>

